import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@ui/icon/icon.component';
import { FooterModule } from '@ui/footer/footer.module';
import { ContainerComponent } from '@ui/container/container.component';

@Component({
	standalone: true,
	selector: 'tk-not-found',
	imports: [CommonModule, RouterLink, IconComponent, FooterModule, ContainerComponent],
	templateUrl: './not-found.component.html',
	styleUrl: './not-found.component.sass',
})
export class NotFoundComponent {}
