import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { ModalContainerComponent } from '@components/src/modals/modal-container/modal-container.component';
import { DialogService } from '@services/dialog.service';
import { WINDOW } from '@ng-web-apis/common';
import { headerNavigation } from '@models/constants/header-navigation';
import { FeedbackModalComponent } from '@technokratos-public/components/modals/feedback/feedback-modal/feedback-modal.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TkButtonModule } from '@technokratos/components-angular/button';
import { IconComponent } from '@ui/icon/icon.component';
import { DialogCloseDirective } from '@directives/src/dialog-close.directive';
import { NgForOf } from '@angular/common';

@Component({
	standalone: true,
	selector: 'tk-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.sass', '../../ui/header/header-shared.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [RouterLink, TkButtonModule, IconComponent, DialogCloseDirective, NgForOf, RouterLinkActive],
})
export class MobileMenuComponent {
	routes = headerNavigation;

	private dialogService = inject(DialogService);
	private _window = inject(WINDOW);

	@HostBinding('class') get hostClass() {
		const mainClass = 'main-page-header';
		return [mainClass, `${mainClass}--lightText`];
	}

	scrollToTop() {
		this._window.scrollTo({ top: 0 });
	}

	openModal = () => {
		return this.dialogService.openDialog(FeedbackModalComponent, {
			width: '1400px',
			disableClose: true,
			container: ModalContainerComponent,
			backdropClass: 'cdk-overlay-backdrop--black',
		});
	};
}
