import { inject } from '@angular/core';
import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

export const HttpErrorsInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const router = inject(Router);

	return next(request).pipe(
		catchError((err: HttpErrorResponse) => {
			if (err.status === 404) {
				router.navigateByUrl('/404');
			}

			return throwError(() => err);
		}),
	);
};
