<header (toggleHeader)="toggleHeader($event)" class="main-page-header__content" scrollHeader>
	<a [class.logo-mobile]="isMenuActive" class="main-page-header__logo" routerLink="/">
		<tk-icon aria-label="ТЕХНОКРАТИЯ" icon="main_logo" />
	</a>
	<nav class="list-wrapper">
		<ul class="list">
			<li *ngFor="let route of routes">
				<a [routerLink]="route.url" class="list__item" routerLinkActive="list__item--active">
					{{ route.name }}
				</a>
			</li>
		</ul>
	</nav>
	<tk-button-group>
		<button (click)="openModal()" [theme]="getFeedbackButtonTheme" class="main-page-header__feedback" tkButton>
			Написать нам
		</button>
		<button (click)="openMobileMenu()" class="main-page-header__icon main-page-header__icon--burger">
			<tk-icon
				[attr.aria-label]="isMenuActive ? 'Закрыть' : 'Открыть мобильное меню'"
				[class.logo-mobile]="isMenuActive"
				[icon]="isMenuActive ? 'cross' : 'burger'"
			/>
		</button>
	</tk-button-group>
</header>
