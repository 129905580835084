<tk-modal-header />
<div class="feedback__container">
	<tk-side-name>Написать нам</tk-side-name>
	<section class="feedback__info">
		<h2>О чём поговорим?</h2>
		<ul class="feedback__list">
			<li class="card card--one">
				<button (click)="openFeedbackForm(feedbackModalType.project)" class="card__item">
					<tk-icon icon="arrow_top_right" />
					<span>У&nbsp;меня<br />есть проект</span>
				</button>
			</li>
			<li class="card card--two">
				<button (click)="openFeedbackForm(feedbackModalType.employee)" class="card__item">
					<tk-icon icon="arrow_top_right" />
					<span>Хочу стать<br />технократом</span>
				</button>
			</li>
			<li class="card card--three">
				<button (click)="openFeedbackForm(feedbackModalType.media)" class="card__item">
					<tk-icon icon="arrow_top_right" />
					<span>Я&nbsp;из<br />медиа</span>
				</button>
			</li>
			<li class="card card--four">
				<button (click)="openFeedbackForm(feedbackModalType.partner)" class="card__item">
					<tk-icon icon="arrow_top_right" />
					<span>Хочу стать<br />партнёром</span>
				</button>
			</li>
		</ul>
	</section>
</div>
<tk-lazy-image
	alt="feedback wing"
	class="feedback__image-wrapper"
	fill
	objectFit="contain"
	pathToImage="index/feedback-wing.webp"
/>
