import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalHeaderComponent } from '@technokratos-public/components/modals/modal-header/modal-header.component';
import { SideNameComponent } from '@ui/side-name/side-name.component';
import { SidebarComponent } from '@ui/sidebar/sidebar.component';
import { FeedbackComponent } from '@technokratos-public/utils/FeedbackClass';
import { ComponentsModule } from '@components/components.module';
import { LazyImageComponent } from '@technokratos-public/components/ui/lazy-image/lazy-image.component';

@Component({
	standalone: true,
	selector: 'tk-feedback-modal',
	templateUrl: './feedback-modal.component.html',
	styleUrls: ['./feedback-modal.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ModalHeaderComponent, SideNameComponent, SidebarComponent, ComponentsModule, LazyImageComponent],
})
export class FeedbackModalComponent extends FeedbackComponent {}
