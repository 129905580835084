import { ChangeDetectorRef, Component, inject, Renderer2 } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { DOCUMENT, NgIf } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Dialog } from '@angular/cdk/dialog';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { DialogService } from '@services/dialog.service';
import { Overlay } from '@angular/cdk/overlay';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderTheme } from '@models/enums/header-theme.enum';
import { HeaderComponent } from '@technokratos-public/components/ui/header/header.component';
import { HeaderService } from '@technokratos-public/services/header.service';
import { CookiesModalComponent } from '@technokratos-public/components/modals/cookies-modal/cookies-modal.component';
import { CookieThemeService } from '@technokratos-public/services/cookie-theme.service';

@Component({
	standalone: true,
	selector: 'technokratos-public-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.sass'],
	imports: [HeaderComponent, RouterOutlet, NgIf],
})
export class AppComponent {
	hideHeader = false;
	isCookieDialogOpen = false;

	constructor() {
		const destroy$ = inject(DestroyService);
		const cdr = inject(ChangeDetectorRef);
		const document = inject(DOCUMENT);
		const cookieThemeService = inject(CookieThemeService);
		const headerService = inject(HeaderService);
		const cookiesService = inject(CookieService);
		const renderer = inject(Renderer2);
		const route = inject(ActivatedRoute);
		const platform = inject(Platform);
		const dialogService = inject(DialogService);
		const overlay = inject(Overlay);

		inject(Dialog)
			.afterOpened.pipe(
				filter(dialog => !(dialog.componentInstance instanceof CookiesModalComponent)),
				tap(() => {
					document.body.firstElementChild?.setAttribute('aria-hidden', 'false');
					renderer.setStyle(document.body, 'overflowY', 'hidden');

					[].slice
						.call(document.getElementsByClassName('cdk-visually-hidden cdk-focus-trap-anchor'))
						.forEach((element: HTMLElement) => {
							element.setAttribute('aria-hidden', 'false');
							element.setAttribute('disabled', 'true');
						});
				}),
				switchMap(dialog => dialog.closed),
				takeUntil(destroy$),
			)
			.subscribe(() => renderer.setStyle(document.body, 'overflowY', 'initial'));

		inject(Router)
			.events.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => route.snapshot),
				map(route => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				tap(event => {
					const theme = event.data['theme'] || HeaderTheme.DARK;
					const updatedParams = {
						theme,
						...event.data,
					};

					headerService.update(updatedParams);
					cookieThemeService.changeTheme(theme === HeaderTheme.DARK ? theme : HeaderTheme.LIGHT);
				}),
				takeUntil(destroy$),
			)
			.subscribe(event => {
				const isDocPage = !!event.url.find(
					u => u.path.includes('compliance') || u.path.includes('privacy-policy'),
				);

				this.hideHeader = isDocPage;

				if (
					!this.isCookieDialogOpen &&
					!isDocPage &&
					platform.isBrowser &&
					!cookiesService.get('cookiesAgreement')
				) {
					dialogService.openStandAloneDialog(CookiesModalComponent, {
						width: '1376px',
						hasBackdrop: false,
						scrollStrategy: overlay.scrollStrategies.noop(),
						backdropClass: 'cdk-cookies-dialog-backdrop',
						panelClass: 'cookies-dialog-container',
					});

					this.isCookieDialogOpen = true;
				}

				cdr.markForCheck();
			});
	}
}
