<tk-container>
	<section class="content">
		<section class="image__container">
			<img class="image__cover" src="/assets/images/errors/404.webp" alt="not found" />
			<img class="image__background" src="/assets/images/errors/404-background.webp" alt="not found" />
		</section>

		<section class="text">
			<p class="title">Страница не найдена</p>
			<p class="subtitle">
				Кажется, истины вы тут не найдете. Попробуйте поискать ее в вине, или на других страницах. Там тоже
				много интересного.
			</p>
			<a routerLink="/" class="link">
				<tk-icon class="link__icon" icon="link" size="24" />
				Перейти на главную
			</a>
		</section>
	</section>

	<tk-footer />
</tk-container>
