import { APP_ID, ApplicationConfig, LOCALE_ID, Provider } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { UrlInterceptor } from '@services/url-interceptor.service';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { IMAGE_LOADER, ImageLoaderConfig, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { HttpErrorsInterceptor } from '@services/http-errors.interceptor';
import { DEFAULT_TOAST_CONFIG } from '@models/constants/toast-config';

registerLocaleData(localeRu);

const CustomProviders: Provider[] = [
	CookieService,
	{ provide: APP_ID, useValue: 'serverApp' },
	{
		provide: LOCALE_ID,
		useValue: 'ru-RU',
	},
	{
		provide: IMAGE_LOADER,
		useValue: (config: ImageLoaderConfig) => {
			return `assets/images/${config.src}`;
		},
	},
];

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(
			routes,
			withEnabledBlockingInitialNavigation(),
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
		),
		provideAnimations(),
		provideToastr(DEFAULT_TOAST_CONFIG),
		provideHttpClient(withFetch(), withInterceptors([UrlInterceptor, HttpErrorsInterceptor])),
		provideClientHydration(),
		...CustomProviders,
	],
};
