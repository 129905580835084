import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderTheme } from '@models/enums/header-theme.enum';

export interface HeaderParams {
	theme: HeaderTheme;
	textColorTheme?: HeaderTheme;
	reverseTheme?: HeaderTheme;
	reverseTextColorTheme?: HeaderTheme;
}

@Injectable({
	providedIn: 'root',
})
export class HeaderService implements OnDestroy {
	headerParams = new BehaviorSubject<HeaderParams>({
		theme: HeaderTheme.DARK,
		textColorTheme: HeaderTheme.LIGHT,
	});

	update(params: HeaderParams) {
		this.headerParams.next(params);
	}

	ngOnDestroy() {
		this.headerParams.complete();
	}
}
