import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, inject, signal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DialogService } from '@services/dialog.service';
import { HeaderTheme } from '@models/enums/header-theme.enum';
import { ButtonTheme } from '@models/button-theme';
import { headerNavigation } from '@models/constants/header-navigation';
import { ScrollHeaderDirective } from '@directives/src/scroll-header.directive';
import { NgClass, NgForOf } from '@angular/common';
import { TkButtonModule } from '@technokratos/components-angular/button';
import { IconComponent } from '@ui/icon/icon.component';
import { HeaderParams, HeaderService } from '@technokratos-public/services/header.service';
import { FeedbackModalComponent } from '@technokratos-public/components/modals/feedback/feedback-modal/feedback-modal.component';
import { MobileMenuComponent } from '@technokratos-public/components/modals/mobile-menu/mobile-menu.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	standalone: true,
	selector: 'tk-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.sass', 'header-shared.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ScrollHeaderDirective, NgClass, RouterLink, RouterLinkActive, NgForOf, TkButtonModule, IconComponent],
})
export class HeaderComponent {
	type = HeaderTheme;
	isMenuActive = false;
	isReverseHeader = false;
	headerParams = signal<HeaderParams>({
		theme: HeaderTheme.DARK,
		textColorTheme: HeaderTheme.LIGHT,
	});
	routes = headerNavigation;

	ThemeStyle = {
		[HeaderTheme.LIGHT]: {
			class: 'light',
		},
		[HeaderTheme.DARK]: {
			class: 'dark',
		},
		[HeaderTheme.BLUE]: {
			class: 'blue',
		},
		[HeaderTheme.TRANSPARENT]: {
			class: 'transparent',
		},
	};

	TextStyle = {
		[HeaderTheme.LIGHT]: {
			class: 'lightText',
		},
		[HeaderTheme.DARK]: {
			class: 'darkText',
		},
		[HeaderTheme.BLUE]: {
			class: 'blueText',
		},
		[HeaderTheme.TRANSPARENT]: {
			class: '',
		},
	};

	private dialogService = inject(DialogService);

	constructor() {
		const destroy$ = inject(DestroyRef);

		inject(HeaderService)
			.headerParams.pipe(takeUntilDestroyed(destroy$))
			.subscribe(params => this.headerParams.set(params));
	}

	@HostBinding('class')
	get hostClasses() {
		const hostClass = 'main-page-header';

		return {
			'modal-open': this.isMenuActive,
			[hostClass]: true,
			[`${hostClass}--${this.ThemeStyle[this.theme].class}`]: true,
			[`${hostClass}--${this.TextStyle[this.textColorTheme].class}`]: true,
			[`${hostClass}--scrolled`]: this.isReverseHeader,
		};
	}

	get theme(): HeaderTheme {
		return this.isReverseHeader && this.headerParams().reverseTheme
			? this.headerParams().reverseTheme!
			: this.headerParams().theme;
	}

	get textColorTheme() {
		return (
			(this.isReverseHeader && !!this.headerParams().reverseTextColorTheme
				? this.headerParams().reverseTextColorTheme
				: this.headerParams().textColorTheme) || HeaderTheme.LIGHT
		);
	}

	get getFeedbackButtonTheme(): ButtonTheme {
		if (this.textColorTheme === this.type.BLUE || this.theme === this.type.BLUE) {
			return this.isMenuActive ? 'primary' : 'inline';
		}
		if (this.textColorTheme === this.type.DARK || this.theme === this.type.LIGHT) {
			return 'raised';
		}

		return 'ghost';
	}

	openModal(): void {
		this.dialogService.openDialog(FeedbackModalComponent, {
			width: '1400px',
			backdropClass: 'cdk-overlay-backdrop--black',
		});
	}

	toggleHeader(flag: boolean): void {
		this.isReverseHeader = flag;
	}

	openMobileMenu() {
		this.dialogService.openStandAloneDialog(MobileMenuComponent, {
			width: '100%',
			height: '100%',
			maxWidth: '100vw',
		});
	}
}
