<header class="main-page-header__content">
	<a class="main-page-header__logo" routerLink="/" tkDialogClose>
		<tk-icon aria-label="ТЕХНОКРАТИЯ" icon="main_logo" />
	</a>
	<tk-button-group>
		<button (click)="openModal()" class="feedback-button feedback-button--top" theme="ghost" tkButton>
			Написать нам
		</button>
		<button class="main-page-header__icon" tkDialogClose>
			<tk-icon icon="cross" />
		</button>
	</tk-button-group>
</header>

<div class="main-page-header__info">
	<nav class="header-nav">
		<ul class="header-nav__list">
			<li *ngFor="let route of routes" class="header-nav__item">
				<a
					(click)="scrollToTop()"
					[routerLink]="route.url"
					class="header-nav__link"
					routerLinkActive="header-nav__link--active"
					tkDialogClose
					>{{ route.name }}</a
				>
			</li>
		</ul>
	</nav>

	<div class="footer">
		<button (click)="openModal()" class="feedback-button--bottom" theme="ghost" tkButton>Написать нам</button>

		<div class="footer__contacts">
			<a class="footer__email" href="mailto:sales@technokratos.com">sales&#64;technokratos.com</a>
			<address class="footer__address">Казань, Кремлёвская, 21</address>
		</div>
	</div>
</div>
